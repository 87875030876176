import './contact.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import log from 'loglevel';

export function Contact() {
    const [contact, setContact] = useState([]);
    useEffect(() => {
        document.title = 'Contact';
    });

    useEffect(() => {
        axios.get('/items/contact')
            .then(response => {
                log.info("response", response);
                setContact(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    const createListItems = (data) => {
        const httpsUrlPattern = new RegExp('^https://', 'i');

        return data.split('\n').map((item, index) => {
            if (httpsUrlPattern.test(item)) {
                return <li key={index}><a href={item} target='_bland'>{item}</a></li>
            } else {
                return <li key={index}>{item}</li>
            }
        });
    }
        
    return (
        <div className='contactPage'>
            <h2>Band</h2>
            <ul>{createListItems(contact.email || '')}</ul>
            <h2>Booking</h2>
            <ul>{createListItems(contact.booking || '')}</ul>
        </div>
    );
}