import "./home.css";
import React, {useEffect} from "react";

import antimatter from "../img/The Antimatter Fantasy.png";

export function Home() {
    useEffect(() => {
        document.title = 'Home';
    });

    return (
        <div className="homePage">
            <img src={antimatter} alt="Antimatter" />
            <p style={{textAlign: "center"}}>OUT NOW</p>
        </div>
    );
}
