import './discography.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import log from 'loglevel';
import { Card, Flex } from 'antd';

import antimatter from '../img/antimatter.png';
import vileentity from '../img/vileentity.png';
import cataclysm from '../img/cataclysm.png';

const {Meta} = Card;

export function Discography() {
    const [discography, setDiscography] = useState([]);

    useEffect(() => {
        document.title = 'Discography';
    });

    useEffect(() => {
        axios.get('/items/discography')
            .then(response => {
                log.info("response", response);
                setDiscography(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    const fullLengthAlbums = discography.filter(item => item.type === "Full-length");
    //const _singles = discography.filter(item => item.type === "Single"); 

    const albumCovers = {
        "The Antimatter Fantasy": antimatter,
        "The Vile Entity": vileentity,
        "The Cataclysm": cataclysm
    };

    return (
        <div className='discographyPage'>
            <h2>Albums</h2>
            <Flex justify="space-between">
                {fullLengthAlbums.map((album) => {
                    return (
                        <Link key={album.id} to={`/discography/${album.id}`}>
                            <Card
                                hoverable
                                style={{ width: 400 }}
                                cover={<img alt="example" src={albumCovers[album.album_title]} />}
                            >
                                <Meta title={album?.album_title} description={`Release date: ${album?.release_date} `} />

                          </Card>
                      </Link>
                    );
                })}
            </Flex>
            {/* <h3>Singles</h3>
            <ul>
                {singles.map((single) => {
                    return (
                        <li key={single.id}>
                            <Link to={`/discography/${single.id}`}>{single.title}</Link>
                        </li>
                    );
                })}
            </ul> */}
        </div>
    );
}