import './band.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import bandPicture from '../img/Numento-2024-vaakakuva.jpg';
import axios from 'axios';

import log from 'loglevel';
import { Space } from 'antd';

export function Band() {
    const [band, setBand] = useState([]);
    useEffect(() => {
        document.title = 'Band';
    });

    useEffect(() => {
        axios.get('/items/band')
            .then(response => {
                log.info("response", response);
                setBand(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    return (
        <div className="bandPage">            
            <img src={bandPicture} alt="Numento" />
            <div>
                <Space direction="horizontal">
                    <Link to="/band/5">Simo</Link>
                    <Link to="/band/2">Atte</Link>
                    <Link to="/band/1">Katri</Link>
                    <Link to="/band/3">Aleksi</Link>
                    <Link to="/band/4">Mikko</Link>
                </Space>
            </div>
            <div className='history'>
                <h2 className="bandName">Numento history</h2>
                <div className="bandInfo">
                    {band.history}
                </div>
            </div>
        </div>
    );
}