import './gigs.css';
import axios from "axios";
import log from "loglevel";
import React, {useEffect, useState} from "react";
import { Table } from 'antd';
import { format } from 'date-fns';


export function Gigs() {

    const [gigs, setGigs] = useState([]);

    useEffect(() => {
        document.title = 'Gigs';
    });
    useEffect(() => {
        axios.get('/items/gigs')
            .then(response => {
                log.info("response", response);
                setGigs(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });        
    }, []);

    const columns = [   
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                <p>{format(new Date(record.date), 'MMMM d, yyyy')}</p>
            ),
        },
        {
            title: 'Venue',
            dataIndex: 'place',
            key: 'place',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
        },
        {  
            title: 'Tickets',
            dataIndex: 'ticket_url',
            key: 'ticket_url',
            render: (text, record) => record.ticket_url === null ? null : <a href={record.ticket_url}>Buy tickets</a>,
        },
        {
            title: 'Facebook event',
            dataIndex: 'facebook_url',
            key: 'facebook_url',
            render: (text, record) => (
                <a href={record.facebook_event}>Facebook event</a>
            ),
        },
    ];

    return (
        <div className="gigsPage">
            <Table 
                size='large'
                style={{backgroundColor: "grey"}}
                pagination={false} 
                dataSource={gigs} 
                columns={columns} 
                expandable={{
                    expandedRowRender: (record) => (
                      <p
                        style={{
                          margin: 0,
                        }}
                      >
                        {record.extra_info}
                      </p>
                    ),
                    rowExpandable: (record) => record.extra_info === undefined,
                  }}    
            />
        </div>
    );
}