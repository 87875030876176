import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Webpage from './App';
import reportWebVitals from './reportWebVitals';
import { App, ConfigProvider, Alert } from 'antd';
import { Navigate, Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

// eslint-disable-next-line
import log from 'loglevel';
import { News } from './pages/news';
import { Discography } from './pages/discography';
import { Album } from './pages/album';
import { Band } from './pages/band';
import { Member } from './pages/member';
import { Gigs } from './pages/gigs';
import { Contact } from './pages/contact';
import { Home } from './pages/home';



log.setLevel("debug");
try {
    const root = ReactDOM.createRoot(document.getElementById('root'));

    root.render(
        <React.Suspense fallback={<div>Loading...</div>}>
            <ConfigProvider locale="en_US" theme={{token: {fontFamily: "Roboto Condensed-Regular"}}}>     
                <App>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Webpage />}>
                                <Route index element={<Navigate to="home"/>} />
                                <Route path="/home" element={<Home />} />
                                <Route path="/news" element={<News />} />
                                <Route path="/discography" element={<Discography />} />                
                                <Route path="/discography/:albumId" element={<Album />} />
                                <Route path="/band" element={<Band />} />
                                <Route path="/band/:memberId" element={<Member />} />
                                <Route path="/gigs" element={<Gigs />} />
                                <Route path="/contact" element={<Contact />} />
                            </Route>                
                        </Routes>
                    </BrowserRouter>
                </App>
            </ConfigProvider>
        </React.Suspense>

    );
} catch (error) {
    log.error('Failed to create root');
    log.error(error);
    const container = document.getElementById('root');
    const root = ReactDOM.createRoot(container);
    log.info("Trying to error");
  
    root.render(
        <React.StrictMode>
            <Alert
                className="error-boundary"
                message={'Index.errorMessage'}
                description={'Index.errorDescription'}
                type="error"
                showIcon
            />
        </React.StrictMode>
    );
}    

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
