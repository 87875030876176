import './album.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import log from 'loglevel';

import antimatter from '../img/antimatter.png';
import vileentity from '../img/vileentity.png';
import cataclysm from '../img/cataclysm.png';

export function Album() {
    const albumId = useParams().albumId;
    const [album,setAlbum] = useState(undefined);


    useEffect(() => {
        document.title = 'Record ' + album?.album_title;
    });

    useEffect(() => {
        axios.get(`/items/discography/${albumId}`)
            .then(response => {
                console.log("response", response);
                setAlbum(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [albumId]);

    const albumCovers = {
        "The Antimatter Fantasy": antimatter,
        "The Vile Entity": vileentity,
        "The Cataclysm": cataclysm
    };

    log.info("album", album);    


    return (
        <div className="albumPage">
            <div className="albumLeft">
                <img src={albumCovers[album?.album_title]} alt={album?.album_title} />
            </div>
            <div className='albumLeft'>   
                <h2>{album?.album_title}</h2>

            </div>
        </div>
    );
}