import './member.css';
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import aleksi from '../img/aleksi.jpg';
import atte from '../img/atte.jpg';
import katri from '../img/katri.jpg';
import mikko from '../img/mikko.jpg';
import simo from '../img/simo.jpg';

export function Member() {
    const memberId = useParams().memberId;
    const [member, setMember] = useState(undefined);
    useEffect(() => {
        document.title = member?.Name;
    });

    useEffect(() => {
        axios.get(`/items/members/${memberId}`)
            .then(response => {
                console.log("response", response);
                setMember(response.data.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [memberId]);

    var memberImage = "";
    switch (memberId) {
        case "1":
            memberImage = katri;
            break;
        case "2":
            memberImage = atte;
            break;
        case "3":
            memberImage = aleksi;
            break;
        case "4":
            memberImage = simo;
            break;
        case "5":
            memberImage = mikko;
            break;
        default:
            memberImage = "";
            break;
    }


    return (
        <div className="memberPage">
            <div className="memberLeft">
                <div className="memberPicture">
                    <img src={memberImage} alt={member?.Name} />
                </div>
                <div className='memberInfo'>
                    <h2>{member?.Name}</h2>
                    <h2>{member?.instrument}</h2>
                    <p>{member?.introduction}</p>
                </div>
            </div>
        </div>
    );
}